import { Component, OnInit,ElementRef, OnDestroy  } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-typography',
    templateUrl: './typography.component.html',
    styleUrls: ['./typography.component.scss']
})
export class TypographyComponent implements OnInit, OnDestroy {


  constructor( private element : ElementRef) {}

  ngOnInit() {
    const body = document.getElementsByTagName('app-typography')[0];
    let navbar = document.getElementsByTagName('app-navbar')[0].children[0];
    //navbar.classList.add('navbar-hidden');
    body.classList.add('demo-icons');
}

ngOnDestroy(){
    let navbar = document.getElementsByTagName('app-navbar')[0].children[0];
    navbar.classList.remove('navbar-hidden');
}

 

}
