import { Component, OnInit ,ElementRef, OnDestroy  } from '@angular/core';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit, OnDestroy {

  constructor( private element : ElementRef) {}

  ngOnInit() {
    const body = document.getElementsByTagName('app-landing')[0];
    let navbar = document.getElementsByTagName('app-navbar')[0].children[0];
    //navbar.classList.add('navbar-hidden');
    body.classList.add('demo-icons');
}

ngOnDestroy(){
    let navbar = document.getElementsByTagName('app-navbar')[0].children[0];
    navbar.classList.remove('navbar-hidden');
}

}
